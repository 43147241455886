<template>
  <v-app id="inspire">
    <v-main>
      <v-container
        class="fill-height"
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="8"
            md="4"
          >
            <v-card class="elevation-12">
              <v-toolbar
                color="primary"
                dark
                flat
              >
                <v-toolbar-title>Página no encontrada</v-toolbar-title>
              </v-toolbar>

              <v-card-text>
                <h1>No pudimos encontrar lo que estás buscando.</h1>
              </v-card-text>

              <v-card-actions class="d-flex justify-center">
                <v-btn
                  color="primary"
                  to="/"
                >
                  <v-icon left>mdi-home</v-icon>
                  Ir a inicio
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import AuthAPI from '@/api/auth';

export default {
  name: 'Login',

  data: () => ({
    form: {
      email: null,
      password: null,
    },
    notification: {
      show: false,
      message: null,
    },
  }),

  created() {
    this.api = new AuthAPI();
  },

  methods: {
    async login() {
      this.$store.commit('showLoader');
      try {
        const tokens = await this.api.login(this.form);
        this.$store.dispatch('auth/setTokens', tokens);
        this.$router.push('/');
      } catch (error) {
        switch (error.status) {
          case 400:
          case 401:
            this.notification.message = 'Credenciales incorrectas.';
            break;

          default:
            this.notification.message = 'Ocurrió un error. Por favor, vuelve a intentar.';
        }

        this.notification.show = true;
      }
      this.$store.commit('hideLoader');
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
